import React from "react";
import {
  Connect4GameCode,
  Connect4GameView,
  Connect4StarterCode,
  Connect4Description,
  Connect4VisualMap,
  Connect4ProblemStatement,
  Connect4EmptyBoard,
} from "../Games/Connect4";
import { Connect4PlayView } from "../PlayBots/Connect4PlayView";
import { Connect4BattleView } from "../BattleBots/Connect4BattleView";
import {
  ChessGameView,
  ChessVisualMap,
  ChessDescription,
  ChessProblemStatement,
  ChessStarterCode,
  ChessGameCode,
  ChessEmptyBoard,
} from "../Games/Chess";
import { ChessPlayView } from "../PlayBots/ChessPlayView";
import { ChessBattleView } from "../BattleBots/ChessBattleView";
import {
  FillerGameCode,
  FillerGameView,
  FillerStarterCode,
  FillerDescription,
  FillerProblemStatement,
} from "../Games/Filler";
import c4Img from "../Images/Game Covers/connect4_cover.png";
import chessImg from "../Images/Game Covers/chess_cover.png";
import othelloImg from "../Images/Game Covers/othello_cover.png";
import goImg from "../Images/Game Covers/go_cover.png";
import checkerImg from "../Images/Game Covers/checker_cover.png";
import fillerImg from "../Images/Game Covers/filler_cover.png";
import {
  OthelloDescription,
  OthelloGameCode,
  OthelloGameView,
  OthelloProblemStatement,
  OthelloStarterCode,
  OthelloVisualMap,
  OthelloPlayView,
} from "../Games/Othello";

export const GamesInfo = {
  chess: {
    title: "Chess",
    description: "Checkmate your opponent in this complex strategy game.",
    battleView: (
      player1,
      player2,
      user,
      setShowWinner,
      setWinner,
      setInvalid,
      setTimeoutFlag,
      setError
    ) => {
      return (
        <ChessBattleView
          player1={player1}
          player2={player2}
          user={user}
          setShowWinner={setShowWinner}
          setWinner={setWinner}
          setInvalid={setInvalid}
          setTimeoutFlag={setTimeoutFlag}
          setError={setError}
        />
      );
    },
    gameView: (gameData, setShowWinner) => {
      return (
        <ChessGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    playView: (
      width,
      contestId,
      botId,
      user,
      setException,
      setStdout,
      setInvalid,
      setTimeoutFlag,
      setMoveTime,
      setMove
    ) => {
      return (
        <ChessPlayView
          width={width}
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
          setTimeout={setTimeoutFlag}
          setInvalid={setInvalid}
          setMove={setMove}
          setMoveTime={setMoveTime}
        />
      );
    },
    startBoard: <ChessEmptyBoard />,
    visualMap: ChessVisualMap,
    active: true,
    image: chessImg,
    goal: ChessDescription,
    problemStatement: ChessProblemStatement,
    gameCode: ChessGameCode,
    starterCode: ChessStarterCode,
  },
  connect4: {
    title: "Connect 4",
    description: "Create horizontal, vertical, or diagonal rows of four.",
    battleView: (
      player1,
      player2,
      user,
      setShowWinner,
      setWinner,
      setInvalid,
      setTimeoutFlag,
      setError
    ) => {
      return (
        <Connect4BattleView
          player1={player1}
          player2={player2}
          user={user}
          setShowWinner={setShowWinner}
          setWinner={setWinner}
          setInvalid={setInvalid}
          setTimeoutFlag={setTimeoutFlag}
          setError={setError}
        />
      );
    },
    gameView: (gameData, setShowWinner) => {
      return (
        <Connect4GameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    playView: (
      width,
      contestId,
      botId,
      user,
      setException,
      setStdout,
      setInvalid,
      setTimeoutFlag,
      setMoveTime,
      setMove
    ) => {
      return (
        <Connect4PlayView
          width={width}
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
          setTimeout={setTimeoutFlag}
          setInvalid={setInvalid}
          setMoveTime={setMoveTime}
          setMove={setMove}
        />
      );
    },
    startBoard: <Connect4EmptyBoard />,
    problemStatement: Connect4ProblemStatement,
    visualMap: Connect4VisualMap,
    active: true,
    image: c4Img,
    goal: Connect4Description,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  // TODO: FIX THESE
  filler: {
    title: "Filler",
    description: "Fill the board with your color in this color matching game.",
    gameView: (gameData, setShowWinner) => {
      return (
        <FillerGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    visualMap: {},
    active: false,
    image: fillerImg,
    goal: FillerDescription,
    problemStatement: FillerProblemStatement,
    gameCode: FillerGameCode,
    starterCode: FillerStarterCode,
  },
  othello: {
    title: "Othello",
    description:
      "Collect the most discs by flipping discs and trapping the opponent's pieces.",
    gameView: (gameData, setShowWinner) => {
      return (
        <OthelloGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    playView: (contestId, botId, user, setException, setStdout) => {
      return (
        <OthelloPlayView
          contestId={contestId}
          botId={botId}
          user={user}
          setException={setException}
          setStdout={setStdout}
        />
      );
    },
    visualMap: OthelloVisualMap,
    active: false,
    image: othelloImg,
    goal: OthelloDescription,
    problemStatement: OthelloProblemStatement,
    gameCode: OthelloGameCode,
    starterCode: OthelloStarterCode,
  },
  go: {
    title: "Go",
    description:
      "Place stones on a grid to surround more territory than the opponent.",
    gameView: (moves, gameData, setShowWinner) => {
      return (
        <Connect4GameView
          moves={moves}
          gameData={gameData}
          setShowWinner={setShowWinner}
        />
      );
    },
    visualMap: Connect4VisualMap,
    active: false,
    image: goImg,
    goal: Connect4Description,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  checkers: {
    title: "Checkers",
    description:
      "Elimate all opposing pieces by moving diagonally and jumping over opponents' pieces.",
    gameView: (moves, gameData, setShowWinner) => {
      return (
        <Connect4GameView
          moves={moves}
          gameData={gameData}
          setShowWinner={setShowWinner}
        />
      );
    },
    visualMap: Connect4VisualMap,
    active: false,
    image: checkerImg,
    goal: Connect4Description,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
};
