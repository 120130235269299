import React, { useState } from "react";
import { GamesInfo } from "../Constants/Games";

const InfoPopup = ({ game }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="overscroll-auto dvh-70">
      <button
        onClick={() => setIsPopupOpen(true)}
        className=""
        aria-label="Info"
      >
        <div className="flex items-center justify-center bg-gray-200 text-black mx-2 px-4 py-2 gap-2 rounded-lg shadow hover:bg-gray-100 transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="16" x2="12" y2="12" />
            <line x1="12" y1="8" x2="12.01" y2="8" />
          </svg>
          instructions
        </div>
      </button>

      {isPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 py-12">
          <div className="bg-white w-3/4 max-h-full p-6 rounded-lg shadow-xl overflow-y-auto gap-2">
            <div className="flex items-center justify-center gap-4 p-2">
              <img
                src={GamesInfo[game].image}
                alt={game}
                className="rounded-lg w-8 sm:w-12 sm:h-12"
              />
              <h1 className="text-2xl font-bold text-center text-onSurface">
                {GamesInfo[game].title}
              </h1>
            </div>
            {GamesInfo[game].problemStatement()}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsPopupOpen(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition"
              >
                close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoPopup;
