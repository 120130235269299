import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserLeaderboard } from "../API/User";
import { emojiMap } from "../Constants/Icons";
import Loading from "../Components/Loading";
import SearchBar from "../Components/SearchBar";

const Leaderboard = () => {
  const navigate = useNavigate();

  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredUsers = topUsers.filter((user) =>
    ["username", "name", "company", "school"].some((key) =>
      user[key]
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(searchTerm.toLowerCase().replace(/\s+/g, ""))
    )
  );

  const getTotalBots = (games) =>
    Object.values(games).reduce((total, ids) => total + ids.length, 0);

  useEffect(() => {
    getUserLeaderboard()
      .then((res) => {
        const leaderboardData = res.users.map((user, index) => ({
          ...user,
          rank: index + 1,
          totalBots: user.bots ? getTotalBots(user.bots) : 0,
        }));
        setTopUsers(leaderboardData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="h-screen p-48">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-between p-4 pt-16">
      <div className="w-full flex flex-col gap-2 px-8">
        <div className="text-2xl text-center p-4">leaderboard</div>
        <SearchBar setSearchTerm={setSearchTerm} />
        <ul className="flex flex-col lg:grid lg:grid-cols-2 gap-4">
          {filteredUsers && filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <li
                key={user.uid}
                className={`flex flex-col sm:flex-row cursor-pointer items-center justify-between w-full rounded-lg py-3 px-4 hover:shadow-md hover:scale-[1.03] duration-200 transition-all ease-linear bg-gray-100`}
                onClick={() =>
                  navigate(`/profile/${user.username}`, {
                    state: { uid: user.uid },
                  })
                }
              >
                <div className="flex flex-col sm:flex-row items-center space-x-4">
                  <div
                    className={`text-xl font-bold w-12 h-12 rounded-full flex items-center justify-center ${
                      user.rank === 1
                        ? "bg-yellow-400 text-white"
                        : user.rank === 2
                        ? "bg-gray-400 text-white"
                        : user.rank === 3
                        ? "bg-orange-400 text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {user.rank}
                  </div>
                  <span className="font-medium break-all">
                    {user.username + " " + emojiMap[user.icon]}
                  </span>
                </div>
                <div className="flex items-center space-x-8">
                  <span className="text-gray-600 text-sm">
                    {user.totalBots} submissions
                  </span>
                  <span className="text-blue-600 font-semibold">
                    {user.rating} ⭐
                  </span>
                </div>
              </li>
            ))
          ) : (
            <p className="text-lg text-onSurfaceSecond p-2">No users found</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Leaderboard;
