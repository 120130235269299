import React from "react";
import LoadingBrain from "../Images/Brains/loading.png";

function Loading({ match }) {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center">
      <img src={LoadingBrain} alt="Loading" className="animate-bounce h-40" />
      <p className="text-lg font-semibold text-onSurface">
        {match ? "Bots are battling..." : "Loading, please wait..."}
      </p>
    </div>
  );
}

export default Loading;
