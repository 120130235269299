import React from "react";
import PinataWhite from "../Images/Contests/pinatachesswhite.png";
import PinataBlack from "../Images/Contests/pinatachessblack.png";
import PinataLogo from "../Images/Contests/boxPinata.png";
import HackMidwestLogo from "../Images/Contests/boxHackMidwest.png";

export const contestDetails = [
  {
    title: "Pinata Chess",
    startDate: "2024-11-17T12:00:00",
    endDate: "2024-11-23T12:00:00",
    image: PinataLogo,
    game: "chess",
    navigate: "/contests/pinata2024",
  },
  {
    title: "HackMidwest Connect 4",
    startDate: "2024-09-28T12:00:00",
    endDate: "2024-09-29T12:00:00",
    image: HackMidwestLogo,
    game: "connect4",
    navigate: "/contests/hackmidwest2024",
  },
];

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

const formatTime = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/Chicago",
  };
  return date.toLocaleTimeString("en-US", options) + " CST";
};

export const getContestStatus = (startDate, endDate) => {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (now < start) {
    return {
      date: formatDate(startDate),
      time: formatTime(startDate),
      status: "Upcoming",
    };
  } else if (now > end) {
    return {
      date: formatDate(endDate),
      time: formatTime(endDate),
      status: "Expired",
    };
  } else {
    return {
      date: formatDate(endDate),
      time: formatTime(endDate),
      status: "Active",
    };
  }
};

export const contestIcons = {
  pinata2024chess: PinataLogo,
};

export const companyIcons = {
  pinata: PinataLogo,
  hackmidwest: HackMidwestLogo,
};

export const chessContestMap = {
  pinata2024chess: {
    wN: ({ squareWidth, isDragging }) => (
      <img
        style={{
          width: isDragging ? squareWidth * 1.75 : squareWidth,
          height: isDragging ? squareWidth * 1.75 : squareWidth,
        }}
        src={PinataWhite}
        alt={"white knight"}
      />
    ),
    bN: ({ squareWidth, isDragging }) => (
      <img
        style={{
          width: isDragging ? squareWidth * 1.75 : squareWidth,
          height: isDragging ? squareWidth * 1.75 : squareWidth,
        }}
        src={PinataBlack}
        alt={"black knight"}
      />
    ),
  },
};
