import React, { useState, useEffect } from "react";
import Chessboard from "chessboardjsx";
import { Chess } from "chess.js";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  atomDark as dark,
  base16AteliersulphurpoolLight as light,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { TbChess, TbChessFilled } from "react-icons/tb";
import { chessContestMap } from "../Constants/Contests";

export const chessStyles = {
  lightSquare: {
    backgroundColor: "#ffccf9",
  },
  darkSquare: {
    backgroundColor: "#ff99c8",
  },
};

export const ChessEmptyBoard = () => {
  return (
    <Chessboard
      width={300}
      position={"start"}
      lightSquareStyle={chessStyles.lightSquare}
      darkSquareStyle={chessStyles.darkSquare}
      boardStyle={{
        borderRadius: "5px",
        overflow: "hidden",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
      }}
    />
  );
};

export const ChessGameView = ({ gameData, setShowWinner }) => {
  const moves = eval(gameData.moves);

  const [game, setGame] = useState(new Chess());
  const [position, setPosition] = useState(game.fen());
  const [currentMove, setCurrentMove] = useState(0);
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    if (currentMove < moves.length && showAnimation) {
      const timeout = setTimeout(() => {
        if (currentMove < moves.length) {
          const move = moves[currentMove];
          game.move({
            from: move.slice(0, 2),
            to: move.slice(2, 4),
            promotion: move.length > 4 ? move.slice(4, 5) : "q",
          });
          setPosition(game.fen());
          if (currentMove + 1 == moves.length) {
            setShowAnimation(false);
            setShowWinner(true);
          }
          setCurrentMove(currentMove + 1);
        } else {
          setShowAnimation(false);
          setShowWinner(true);
        }
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [currentMove, showAnimation]);

  const stepForward = () => {
    if (currentMove < moves.length) {
      const move = moves[currentMove];
      game.move({
        from: move.slice(0, 2),
        to: move.slice(2, 4),
        promotion: move.length > 4 ? move.slice(4, 5) : "q",
      });
      setPosition(game.fen());
      if (currentMove + 1 == moves.length) {
        setShowAnimation(false);
        setShowWinner(true);
      }
      setCurrentMove(currentMove + 1);
    }
  };

  const stepBackward = () => {
    if (currentMove > 0) {
      if (currentMove >= moves.length - 1) {
        setShowWinner(false);
      }
      game.undo();
      setPosition(game.fen());
      setCurrentMove(currentMove - 1);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <Chessboard
        width={250}
        position={position}
        lightSquareStyle={chessStyles.lightSquare}
        darkSquareStyle={chessStyles.darkSquare}
        pieces={
          chessContestMap[gameData.contestId]
            ? chessContestMap[gameData.contestId]
            : {}
        }
        boardStyle={{
          borderRadius: "5px",
          overflow: "hidden",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
        }}
      />
      {!showAnimation && (
        <div className="flex justify-center gap-2 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={stepBackward}
            disabled={currentMove === 0}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={stepForward}
            disabled={currentMove >= moves.length}
          >
            Next
          </button>
        </div>
      )}
      {currentMove < moves.length ? (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => setShowAnimation(!showAnimation)}
          >
            {showAnimation ? "Pause Autoplay" : "Autoplay"}
          </button>
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 mr-2 bg-gray-300 rounded disabled:opacity-50"
            onClick={() => {
              setCurrentMove(0);
              setGame(new Chess());
              setPosition("start");
              if (moves.length !== 1) {
                setShowWinner(false);
              }
            }}
          >
            Replay
          </button>
        </div>
      )}
    </div>
  );
};

export const ChessVisualMap = {
  1: <TbChess size={70} />,
  2: <TbChessFilled size={70} />,
};

export const ChessDescription = `Checkmate your opponent by trapping their king.`;

export const ChessProblemStatement = () => {
  const isDarkMode = document.documentElement.classList.contains("dark");
  return (
    <div className="space-y-4">
      <section className="pb-1">
        <p>
          You must implement a class with a method makeMove() that takes in a
          board and returns a move.
        </p>
      </section>
      <section className="pb-1">
        <p>
          The game is built upon the python-chess library, where the gameState
          is represented as a chess.Board() object. You must will be given a
          copy of the chess.Board instance and return a legal move as a
          chess.Move type.
        </p>
      </section>
      <section className="pb-1">
        <h2 className="text-lg font-bold dark:text-white text-black">
          3rd party imports:
        </h2>
        <div className="whitespace-pre-wrap">
          {isDarkMode ? (
            <div style={{ fontSize: "16px" }}>
              <SyntaxHighlighter
                language="python"
                style={isDarkMode ? dark : light}
              >
                numpy, torch
              </SyntaxHighlighter>
            </div>
          ) : (
            <SyntaxHighlighter language="python">
              numpy, torch
            </SyntaxHighlighter>
          )}
        </div>
      </section>
      <section className="pb-1">
        <h2 className="text-lg font-bold dark:text-white text-black">
          Constraints:
        </h2>
        <div className="whitespace-pre-wrap">
          <ul className="pl-5 list-disc">
            <li>Time to instantiate class: 2 seconds</li>
            <li>Time per move: 2 seconds</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export const ChessStarterCode = `import numpy as np

# See documentation of the python-chess package 
# https://python-chess.readthedocs.io/en/latest/
import chess

# Fill in the makeMove function
class Player:

  def __init__(self, playerOne: bool):
    pass

  def makeMove(self, gameState: chess.Board) -> chess.Move:
    return np.random.choice([move for move in gameState.legal_moves])


# standardized brAIn rot wrapper for games
class Game:

  @staticmethod
  def legal_moves(game_state, turn):
    return [m for m in game_state.legal_moves]

  @staticmethod
  def detect_win(game_state, turn):
    if game_state.outcome():
      res = game_state.outcome().result()
      if res == "1-0":
        return 1
      elif res == "0-1":
        return 2
      return 3
    return 0

  @staticmethod
  def make_move(game_state, turn, move):
    if move not in game_state.legal_moves:
      return game_state
    game_state.push(move)
    return game_state
`;
export const ChessGameCode = `# the chess class that will be used to judge your code
class Chess:
    def __init__(self):
        self.board = chess.Board()
        self.turn = 1
        self.moves = []

    def detect_win(self):
        if self.board.outcome():
            res = self.board.outcome().result()
            if res == "1-0":
                return 1
            elif res == "0-1":
                return 2
            else:
                return 3
        return 0

    def make_move(self, move):
        if isinstance(move, str):
            move = chess.Move.from_uci(move)
        if move not in self.board.legal_moves:
            return False
        self.board.push(move)
        self.turn = 3 - self.turn
        return True
`;
