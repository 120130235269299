import React from "react";
import BrianRot from "../Images/Logos/brain.png";
import BrainRotText from "../Images/Logos/brainrot_text.png";
import { MdEmail } from "react-icons/md";
import { FaLinkedin, FaGithub, FaDiscord } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TermsOfServicePopup from "../Components/Terms";
import Jack from "../Images/Brains/jack.png";
import Chaitra from "../Images/Brains/chaitra.png";
import Winner from "../Images/Brains/winner.png";

function About() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-surfaceContain-light to-primary-light py-8">
      {/* Hero Section */}
      <section className="container mx-auto text-center px-6 md:px-12 flex flex-col items-center justify-center mt-16">
        <img
          src={BrianRot}
          alt="brAIn rot logo"
          className="w-24 h-24 sm:w-36 sm:h-36 md:w-48 md:h-48 lg:w-48 lg:h-48 mb-4"
        />
        <img
          src={BrainRotText}
          alt="brAIn rot text logo"
          className="w-auto h-20 sm:h-24 md:h-28 lg:h-28"
        />
        <p className="max-w-2xl text-onSurfaceSecond mx-auto mt-8 text-2xl">
          coding contests on games!
        </p>
        <button
          onClick={() => {
            navigate("/games");
          }}
          className="mt-6 inline-block px-8 py-3 text-lg font-semibold text-center text-white bg-primary-light rounded-lg shadow-md hover:bg-primary-dark"
        >
          play
        </button>
      </section>

      {/* Feature Section */}
      <section className="container mx-auto py-16 px-6 md:px-12">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center text-onSurface-light mb-12">
          what can you do?
        </h2>
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3">
          {/* <div
            className="p-6 bg-surface-light rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() => {
              navigate("/courses");
            }}
          >
            <h3 className="text-xl font-semibold text-onSurface-light flex items-center">
              <span role="img" aria-label="code" className="mr-2">
                📚
              </span>
              learn
            </h3>
            <p className="mt-2 text-onSurfaceSecond-light">
              Use our engaging courses to learn ML. We support all levels from
              traditional algorithms to advanced models for experts.
            </p>
          </div> */}
          <div
            className="p-6 bg-surface-light rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() => {
              navigate("/games");
            }}
          >
            <h3 className="text-xl font-semibold text-onSurface-light flex items-center">
              <span role="img" aria-label="visualize" className="mr-2">
                💻
              </span>
              create
            </h3>
            <p className="mt-2 text-onSurfaceSecond-light">
              Practice algorithms and machine learning by creating bots for
              games! We support all levels from traditional algorithms to
              advanced models for experts.
            </p>
          </div>
          <div
            className="p-6 bg-surface-light rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() => {
              navigate("/games");
            }}
          >
            <h3 className="text-xl font-semibold text-onSurface-light flex items-center">
              <span role="img" aria-label="visualize" className="mr-2">
                👾
              </span>
              play
            </h3>
            <p className="mt-2 text-onSurfaceSecond-light">
              Once you create a bot, watch it battle others and even play
              against it yourself! You can visualize your code in a fun and
              engaging way.
            </p>
          </div>
          <div
            className="p-6 bg-surface-light rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() => {
              navigate("/contests");
            }}
          >
            <h3 className="text-xl font-semibold text-onSurface-light flex items-center">
              <span role="img" aria-label="battle" className="mr-2">
                🤺
              </span>
              compete
            </h3>
            <p className="mt-2 text-onSurfaceSecond-light">
              Put your skills to the test by entering contests! This is a great
              way to showcase your code, climb the leaderboard, and earn prizes.
            </p>
          </div>
        </div>
      </section>

      <Testimonials />

      <Contact />

      {/* Call to Action */}
      <section className="py-8">
        <div className="container mx-auto text-center px-6 md:px-12">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
            show what your brAIn can do!
          </h2>
          <button
            onClick={() => {
              navigate("/games");
            }}
            className="mt-6 inline-block px-8 py-3 text-lg font-semibold text-center text-primary bg-white rounded-lg shadow-md hover:bg-primary-dark hover:text-white"
          >
            get started
          </button>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-4 mt-12">
        <div className="container mx-auto text-center">
          <img
            src={BrianRot}
            alt="brAIn rot logo"
            className="w-12 h-12 mx-auto mb-3"
          />
          <p className="flex justify-center mb-4">
            <a
              href="https://www.linkedin.com/company/brain-rot"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 hover:underline mx-2"
            >
              <FaLinkedin className="text-xl" />
              LinkedIn
            </a>
            |
            <a
              href="mailto:brainrot4ai@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 hover:underline mx-2"
            >
              <MdEmail className="text-xl" />
              Email
            </a>
          </p>
          <div className="flex flex-col justify-center gap-2">
            <p>brAIn rot LLC</p>
            <TermsOfServicePopup />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default About;

const founders = [
  {
    name: "Jack Rankin",
    role: "Founder",
    icon: Jack,
    linkedin: "https://www.linkedin.com/in/jack-t-rankin/",
    github: "https://github.com/jackrankin",
  },
  {
    name: "Chaitra Pirisingula",
    role: "Founder",
    icon: Chaitra,
    linkedin: "https://www.linkedin.com/in/chaitra-pirisingula/",
    github: "https://github.com/chaitrapirisingula",
  },
];

const Contact = () => {
  return (
    <div className="py-16">
      <div className="container px-6 mx-auto md:px-12">
        <h2 className="mb-8 text-3xl font-bold text-center text-onSurface">
          contact us
        </h2>
        <p className="mb-12 text-lg text-center text-gray-600">
          have questions or want to collaborate? we’d love to hear from you! 💖
        </p>
        <div className="flex flex-col items-center mb-12">
          <div className="w-full max-w-lg">
            <div className="flex flex-col sm:flex-row justify-between p-6 rounded-lg shadow-lg bg-surfaceContain shadow-md">
              <div className="flex flex-col">
                <h3 className="mb-4 text-2xl font-semibold text-onSurface">
                  reach out
                </h3>
                <p className="text-onSurfaceSecond">email:</p>
                <a
                  href="mailto:brainrot4ai@gmail.com"
                  className="text-blue-600 hover:underline"
                >
                  brainrot4ai@gmail.com
                </a>
                <p className="mt-6 text-onSurfaceSecond">connect:</p>
                <a
                  className="flex items-center gap-2 text-blue-600 hover:underline"
                  href="https://www.linkedin.com/company/brain-rot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="text-xl text-blue-600 dark:text-blue-400" />
                  LinkedIn
                </a>
                <p className="mt-6 text-onSurfaceSecond">community:</p>
                <a
                  className="flex items-center gap-2 text-blue-600 hover:underline"
                  href="https://discord.gg/q2WCq6cV"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaDiscord className="text-xl text-blue-600 dark:text-blue-400" />
                  Discord
                </a>
              </div>
              <div className="flex sm:mt-32">
                <img src={Winner} alt="brAIn" className="w-32 h-32 p-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-12 mb-12">
          {founders.map((member) => (
            <div
              className="flex items-center max-w-xs p-4 rounded-lg shadow-lg bg-surfaceContain"
              key={member.name}
            >
              <img
                src={member.icon}
                alt={member.name}
                className="w-auto h-32 p-4"
              />
              <div>
                <h3 className="text-xl font-semibold text-onSurface">
                  {member.name}
                </h3>
                <p className="text-onSurfaceSecond">{member.role}</p>
                <div className="flex gap-4 mt-2">
                  <a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin className="text-xl text-blue-600" />
                  </a>
                  <a
                    href={member.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGithub className="text-xl text-onSurface" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const testimonials = [
  {
    name: "CS Grad Student @ Purdue",
    quote: "It's like LeetCode + Chess.com",
    icon: "👾",
  },
  {
    name: "CS Student @ University of Nebraska-Lincoln",
    quote: "Fun and a good practice for my algorithm implementation skills",
    icon: "🧠",
  },
  {
    name: "Math Student @ University of California, Berkeley",
    quote:
      "It was a very fun and engaging experience, especially amidst midterm season :)",
    icon: "🥳",
  },
  {
    name: "SWE Intern @ Microsoft",
    quote:
      "I went into a contest with very little machine learning experience and learned so much",
    icon: "📚",
  },
  {
    name: "SWE Intern @ Google",
    quote: "Great way to showcase my code! Now anyone can play against my bots",
    icon: "🤺",
  },
  {
    name: "Avid TikTok watcher",
    quote: "LOVE the brain rot usernames!",
    icon: "🤣",
  },
];

const Testimonials = () => {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          join the fun!
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          loved by developers and students
        </p>
      </div>

      <div className="mt-10 grid gap-6 sm:grid-cols-3 max-w-4xl mx-auto">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="flex flex-col justify-between gap-4 bg-gray-50 shadow-lg rounded-lg p-6 border-[1px] hover:border-primary transition"
          >
            <div className="flex justify-center items-center mb-4 text-4xl">
              {testimonial.icon}
            </div>
            <p className="text-gray-700">{testimonial.quote}</p>
            <p className="text-gray-500">- {testimonial.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
