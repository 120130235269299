import React, { useState, useEffect } from "react";
import Chessboard from "chessboardjsx";
import { Chess } from "chess.js";
import { chessContestMap } from "../Constants/Contests";
import { chessStyles } from "../Games/Chess";
import { getBotMove } from "../API/Bots";

export const ChessPlayView = ({
  width,
  contestId,
  botId,
  user,
  setException,
  setStdout,
  setInvalid,
  setTimeoutFlag,
  setMoveTime,
  setMove,
}) => {
  const [playerColor, setPlayerColor] = useState("white");

  return (
    <div>
      <ChessPlayBot
        width={width}
        key={playerColor} // Changing this key will force a rerender
        contestId={contestId}
        botId={botId}
        user={user}
        playerColor={playerColor}
        setException={setException}
        setStdout={setStdout}
        setTimeout={setTimeoutFlag}
        setInvalid={setInvalid}
        setMoveTime={setMoveTime}
        setMove={setMove}
      />
      <div className="flex justify-center">
        <button
          className="bg-primary text-white rounded-lg p-2 px-4 shadow-md"
          onClick={() =>
            setPlayerColor(playerColor === "white" ? "black" : "white")
          }
        >
          play as {playerColor === "white" ? "black" : "white"}
        </button>
      </div>
    </div>
  );
};

const ChessPlayBot = ({
  width,
  contestId,
  botId,
  user,
  setException,
  setStdout,
  playerColor,
  setInvalid,
  setTimeout,
  setMoveTime,
  setMove,
}) => {
  const [game, _] = useState(new Chess());
  const [position, setPosition] = useState(game.fen());
  const [winner, setWinner] = useState("");

  useEffect(() => {
    setException("");
    if (playerColor == "black") {
      getMove("1");
    }
  }, []);

  const getMove = (turn) => {
    getBotMove(user, "chess", botId, game.fen(), turn)
      .then((result) => {
        if (result.move && !result.invalid) {
          setMoveTime(result.time);
          setMove(result.move);
          const move = result.move;
          game.move({
            from: move.slice(0, 2),
            to: move.slice(2, 4),
            promotion: move.length > 4 ? move.slice(4, 5) : "q",
          });
          setPosition(game.fen());
        }
        if (result.winner) {
          if (result.winner === 1) {
            setWinner(playerColor === "white" ? "You won :)" : "You lost :(");
          } else if (result.winner === 2) {
            setWinner(playerColor === "black" ? "You won :)" : "You lost :(");
          } else {
            setWinner("It's a draw!");
          }
        }

        setStdout(result.stdout);
        setException(result.exception);

        if (result.invalid) {
          setInvalid(true);
        } else if (result.timeout) {
          setTimeout(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onDrop = ({ sourceSquare, targetSquare, piece }) => {
    const pieceColor = piece[0]; // 'w' for white, 'b' for black

    if (
      (playerColor === "white" && pieceColor === "w") ||
      (playerColor === "black" && pieceColor === "b")
    ) {
      const isPawnPromotion =
        piece[1] === "P" &&
        ((pieceColor === "w" && targetSquare[1] === "8") ||
          (pieceColor === "b" && targetSquare[1] === "1"));

      let promotion = "q";

      if (isPawnPromotion) {
        promotion =
          prompt(
            "Choose promotion piece (q = Queen, r = Rook, n = Knight, b = Bishop)",
            "q"
          ) || "q";
      }

      try {
        const move = game.move({
          from: sourceSquare,
          to: targetSquare,
          promotion: isPawnPromotion ? promotion : undefined,
        });

        if (move) {
          setPosition(game.fen());
          getMove(playerColor === "white" ? "2" : "1");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log(
        "Move not allowed: Piece color does not match player's color"
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4 relative">
      {winner ? (
        <p className="text-onSurface p-2">{winner}</p>
      ) : (
        <p className="text-onSurface p-2">play me!</p>
      )}
      <Chessboard
        width={width}
        orientation={playerColor}
        position={position}
        lightSquareStyle={chessStyles.lightSquare}
        darkSquareStyle={chessStyles.darkSquare}
        pieces={
          contestId && chessContestMap[contestId]
            ? chessContestMap[contestId]
            : {}
        }
        boardStyle={{
          borderRadius: "5px",
          overflow: "hidden",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
        }}
        onDrop={onDrop}
      />
    </div>
  );
};
