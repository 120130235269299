import React from "react";
import Winner from "../Images/Brains/contest_winner.png";
import { useNavigate } from "react-router-dom";
import { contestDetails } from "../Constants/Contests";
import { GamesInfo } from "../Constants/Games";
import { getContestStatus } from "../Constants/Contests";

const Contests = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      <div className="w-full bg-surfaceContain-light text-center text-onSurface-light py-12 pb-32 flex flex-col items-center">
        <img className="h-48 pb-8" src={Winner} alt="Winner logo" />
        <div className="text-4xl font-bold pb-4">contests</div>
        <div className="text-md font-medium break-words">
          participate in contests to win prizes!
        </div>
      </div>
      {/* Adding negative margin to overlap the div above */}
      <div className="flex justify-center items-center pt-10 pb-20 -mt-32">
        <div className="flex flex-col sm:flex-row gap-8">
          {contestDetails.map((contest, index) => {
            const { date, time, status } = getContestStatus(
              contest.startDate,
              contest.endDate
            );
            return (
              <div
                key={index}
                onClick={() => navigate(contest.navigate)}
                className="shadow-lg rounded-lg text-center w-72 pb-6 transform transition-transform duration-200 hover:scale-105 cursor-pointer"
              >
                <div className="relative">
                  <img
                    className="w-full mx-auto rounded-t-lg"
                    src={contest.image}
                    alt={`${contest.title} logo`}
                  />
                  <img
                    className="absolute bottom-2 left-2 w-12 h-12 rounded-md"
                    src={GamesInfo[contest.game].image}
                    alt="Game logo"
                  />
                </div>
                <div
                  className={`text-sm font-semibold py-1 rounded-b-lg 
                                    ${
                                      status === "Upcoming"
                                        ? "bg-yellow-200 text-yellow-800"
                                        : status === "Expired"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-green-200 text-green-800"
                                    }
                                `}
                >
                  {status}
                </div>
                <div className="text-2xl font-semibold pt-5 px-6">
                  {contest.title}
                </div>
                <div className="text-gray-600 mt-2 px-6">{date}</div>
                <div className="text-gray-600 px-4">{time}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Contests;
