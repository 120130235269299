import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GamesInfo } from "../Constants/Games";
import { postUserBots } from "../API/User";
import { postBotCreate, postBotEdit, getBotDetails } from "../API/Bots";
import { decodeAllZips } from "../Constants/Helpers";
import InfoPopup from "../Components/InfoPopup";
import NewBotModal from "../Components/NewBotModal";
import JSZip from "jszip";
import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
import NotFound from "../Components/NotFound";
import Building from "../Components/Building";

const Editor = ({ user }) => {
  const { game } = useParams();

  if (!(game.toLowerCase() in GamesInfo)) {
    return (
      <div className="pt-16">
        <NotFound />
      </div>
    );
  }

  if (!GamesInfo[game.toLowerCase()].active) {
    return (
      <div className="pt-16">
        <Building />
      </div>
    );
  }

  const [code, setCode] = useState(GamesInfo[game.toLowerCase()].starterCode);
  const [originalCode, setOriginalCode] = useState(
    GamesInfo[game.toLowerCase()].starterCode
  );
  const [errorTxt, setErrorTxt] = useState("");
  const [stdout, setStdout] = useState("");
  const [stderr, setStderr] = useState("");
  const [move, setMove] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [timeout, setTimeout] = useState(false);
  const [moveTime, setMoveTime] = useState(0);

  const [savedBots, setSavedBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [isNewBotModalOpen, setIsNewBotModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [weights, setWeights] = useState(null);
  const [weightFile, setWeightFile] = useState(null);

  const mirrorRef = useRef(null);

  const hasUnsavedChanges = code !== originalCode || weights !== weightFile;

  useEffect(() => {
    if (errorTxt) {
      const timeout = setTimeout(() => {
        setErrorTxt("");
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [errorTxt]);

  const adjustEditorHeight = () => {
    const timeout = setTimeout(() => {
      const outerContainer = mirrorRef.current.editor;
      // const innerContainer = outerContainer.children[0];
      outerContainer.style.height = "100%";
      // innerContainer.style.height = "100%";
    }, 100);
    return () => clearTimeout(timeout);
  };

  const fetchUserBots = async () => {
    try {
      const result = await postUserBots(user, game.toLowerCase());
      setSavedBots(result.bots);
    } catch (error) {
      console.error("Error fetching bots:", error);
      setErrorTxt("Unable to retrieve saved bots");
    }
  };

  const handleBotSelection = async (botId) => {
    if (hasUnsavedChanges) {
      const confirmSwitch = window.confirm(
        "You have unsaved changes. Are you sure you want to switch bots?"
      );
      if (!confirmSwitch) return;
    }

    try {
      const bot = savedBots.find((b) => b.botId === botId);
      const botDetails = await getBotDetails(
        user,
        game.toLowerCase(),
        bot.botId
      );
      const unzippedFiles = await decodeAllZips(botDetails.zip);

      setSelectedBot(botDetails);
      const playerCode = await unzippedFiles["player.py"].async("string");
      setCode(playerCode);

      setWeights(null);
      setWeightFile(null);

      const additionalFiles = Object.entries(unzippedFiles).filter(
        ([fileName]) => fileName !== "player.py" && fileName !== "__init__.py"
      );

      if (additionalFiles.length > 0) {
        const weightFileName = additionalFiles[0][0];
        const weightFileContent = await additionalFiles[0][1].async(
          "uint8array"
        );

        // Create a File object to match the FileUploader's expectations
        const weightsFile = new File([weightFileContent], weightFileName, {
          type: "application/octet-stream",
        });

        setWeights(weightsFile);
        setWeightFile(weightsFile);
      }

      setOriginalCode(playerCode);
    } catch (error) {
      console.error("Error loading bot details:", error);
      setErrorTxt("Failed to load bot details");
      setCode(GamesInfo[game.toLowerCase()].starterCode);
      setOriginalCode(GamesInfo[game.toLowerCase()].starterCode);
    }
  };

  const createNewBot = async (botName) => {
    try {
      const zip = new JSZip();
      zip.file("player.py", code);

      // Add weights file if present
      if (weights) {
        zip.file(weights.name, weights);
      }

      const files = await zip.generateAsync({ type: "base64" });
      const result = await postBotCreate(
        user,
        botName,
        game.toLowerCase(),
        files
      );
      setSavedBots([...savedBots, result]);
      setSelectedBot(result);
      setOriginalCode(code);
      setWeightFile(weights);
    } catch (error) {
      console.error("Error creating bot:", error);
      setErrorTxt("There was an error creating the bot");
    }
  };

  const editSavedBot = async () => {
    setIsSaving(true);
    if (!selectedBot) {
      setIsNewBotModalOpen(true);
      setIsSaving(false);
      return;
    }

    try {
      const zip = new JSZip();
      zip.file("player.py", code);

      // Add weights file if present
      if (weights) {
        zip.file(weights.name, weights);
      }

      const files = await zip.generateAsync({ type: "base64" });
      await postBotEdit(user, selectedBot.botId, game.toLowerCase(), files);
      setOriginalCode(code);
      setWeightFile(weights);
    } catch (error) {
      console.error("Error saving bot:", error);
      setErrorTxt("There was an error saving your bot");
    } finally {
      setIsSaving(false);
    }
  };

  const saveModelWeights = async (modelWeights) => {
    setIsSaving(true);
    if (!selectedBot) {
      setIsNewBotModalOpen(true);
      setIsSaving(false);
      return;
    }
    try {
      const zip = new JSZip();
      zip.file("player.py", code);
      zip.file(modelWeights.name, modelWeights);
      const files = await zip.generateAsync({ type: "base64" });
      await postBotEdit(user, selectedBot.botId, game.toLowerCase(), files);
      setOriginalCode(code);
      setWeights(modelWeights);
      setWeightFile(modelWeights);
    } catch (error) {
      console.error("Error saving bot:", error);
      setErrorTxt("There was an error saving your bot");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    adjustEditorHeight();
    fetchUserBots();
  }, []);

  return (
    <div>
      <NewBotModal
        isOpen={isNewBotModalOpen}
        onClose={() => setIsNewBotModalOpen(false)}
        onCreateBot={createNewBot}
      />
      <div className="flex h-dvh pt-20">
        <div className="sm:w-2/3 flex flex-col">
          <div className="flex flex-wrap py-3 px-10 justify-between items-center bg-white">
            <div className="flex flex-cols">
              <select
                onChange={(e) => handleBotSelection(e.target.value)}
                value={selectedBot?.botId || ""}
                className="border rounded px-2 py-1"
              >
                <option value="" disabled>
                  bot selection
                </option>
                {savedBots.map((bot) => (
                  <option key={bot.botId} value={bot.botId}>
                    {bot.botname}
                  </option>
                ))}
              </select>

              <button
                onClick={() => setIsNewBotModalOpen(true)}
                className="flex items-center justify-center bg-green-500 text-white hover:bg-green-600 text-black mx-2 px-4 py-2 rounded-lg shadow transition"
              >
                + new bot
              </button>
            </div>
            <InfoPopup game={game.toLowerCase()} />
            <button
              onClick={editSavedBot}
              className={`flex items-center justify-center px-4 py-2 rounded-lg shadow transition ${
                hasUnsavedChanges || !selectedBot
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-gray-200 text-gray-500 cursor-not-allowed"
              }`}
              disabled={!hasUnsavedChanges && selectedBot}
            >
              {isSaving ? "saving..." : "save"}
            </button>
          </div>
          <div className="flex-1 overflow-hidden border-y border-gray-200">
            <CodeMirror
              value={code}
              extensions={[python()]}
              onChange={(value) => {
                setCode(value);
              }}
              ref={mirrorRef}
              basicSetup={{
                lineNumbers: true,
              }}
              style={{
                overflowX: "auto",
                overflowY: "auto",
                fontSize: "12pt",
              }}
            />
          </div>
          {errorTxt ? (
            <div className="bg-red-100 text-red-700 p-4">{errorTxt}</div>
          ) : (
            <div className="flex flex-row justify-between background bg-gray-200 p-1">
              <FileUploader
                file={weights}
                setWeights={setWeights}
                saveModelWeights={saveModelWeights}
              />
              {weights && <div className="pt-2 text-sm">{weights.name}</div>}
            </div>
          )}
        </div>
        <div className="hidden sm:block w-1/3 h-full border-l border-gray-100">
          <div className="pt-4 flex justify-center"></div>
          <div className="flex items-center justify-center">
            {selectedBot ? (
              GamesInfo[game.toLowerCase()].playView(
                270,
                "",
                selectedBot?.botId,
                user,
                setStderr,
                setStdout,
                setInvalid,
                setTimeout,
                setMoveTime,
                setMove
              )
            ) : (
              <div>
                <div className="p-8">
                  {GamesInfo[game.toLowerCase()].startBoard}
                </div>
                <div className="flex justify-center">
                  save your bot to play against it!
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-between border gray p-4 mt-4">
            <div>{"move: " + move}</div>
            <div>{"time: " + moveTime}</div>
          </div>
          {timeout && (
            <div className="text-onSurface p-2">this code timed out!</div>
          )}
          {invalid && (
            <div className="text-onSurface p-2">
              this code made an invalid move!
            </div>
          )}
          <div className="text-red-300">{stderr}</div>
          <div className="text-blue-500">{stdout}</div>
        </div>
      </div>
    </div>
  );
};

export default Editor;

const FileUploader = ({ setWeights, saveModelWeights }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setWeights(file);
      saveModelWeights(file);
      closeModal();
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setWeights(file);
      saveModelWeights(file);
      closeModal();
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-row w-full gap-2 items-center justify-between text-sm p-1">
      <button
        type="button"
        className="bg-blue-500 text-white px-4 py-1 rounded-lg hover:bg-grey-600 transition"
        onClick={openModal}
      >
        upload model weights
      </button>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-96"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-semibold mb-4 text-center">
              upload model weights
            </h2>
            <div
              className="border-2 border-dashed border-blue-500 rounded-lg p-6 text-center"
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <p className="text-gray-600">drag and drop your file here</p>
              <p className="text-gray-600 my-2">or</p>
              <label
                htmlFor="fileInput"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-600 transition inline-block"
              >
                choose file
              </label>
              <input
                id="fileInput"
                type="file"
                accept=".pth"
                className="hidden"
                onChange={handleFileChange}
              />
            </div>
            <button
              onClick={closeModal}
              className="mt-4 w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
            >
              close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
