import React, { useState } from "react";

const TermsOfServicePopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return (
    <div>
      <button onClick={handleOpen}>Terms</button>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
            <h2 className="text-xl font-bold mb-4">Terms of Service</h2>
            <p className="text-sm text-gray-600 mb-4">
              Welcome to brAIn rot LLC! By continuing to use our services on
              brainrotcode.com, you agree to the following:
            </p>
            <ul className="list-disc list-inside text-sm text-gray-600 mb-4">
              <li>
                We collect your full name, school, and company information for
                leaderboards and contests.
              </li>
              <li>
                By entering contests, you provide basic contact information that
                may be shared publicly.
              </li>
              <li>
                Your data may be displayed publicly on leaderboards or other
                contest-related platforms.
              </li>
            </ul>
            <p className="text-sm text-gray-600 mb-4">
              If you do not agree to these terms, please refrain from
              participating in our contests or using our services.
            </p>
            <div className="flex justify-end">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsOfServicePopup;
