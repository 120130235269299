import React from "react";
import HackMidwestLogo from "../Images/Logos/hackmidwest.png";
import TournamentResults from "../Components/Results";
import rounds from "../ContestData/hackmidwestrounds.json";
import finalResults from "../ContestData/hackmidwestfinal.json";
import LogitechKeyboard from "../Images/Hackmidwest/logitech_keyboard.jpg";
import EchoDot from "../Images/Hackmidwest/echo_dot.jpg";
import Airpods from "../Images/Hackmidwest/airpods.png";
import Kirby from "../Images/Hackmidwest/kirby.png";
import ContestDetails from "../Pages/ContestDetails";

const HackMidwest = () => {
  const finals = finalResults
    .sort((a, b) => b.wins - a.wins)
    .map((entry, index) => ({
      ...entry,
      rank: index + 1,
    }));

  const winners = [
    {
      name: "Eric Price",
      username: "@cut_g_fomo141",
      emoji: 17,
      position: "1st",
      bgColor: "bg-gradient-to-r from-yellow-400 to-yellow-600",
    },
    {
      name: "Min-Sung Choi",
      username: "@pibby_quirk354",
      emoji: 34,
      position: "2nd",
      bgColor: "bg-gradient-to-r from-blue-400 to-blue-600",
    },
    {
      name: "Nischay Rawal",
      username: "@wean_daze678",
      emoji: 46,
      position: "3rd",
      bgColor: "bg-gradient-to-r from-orange-400 to-orange-600",
    },
  ];

  return (
    <ContestDetails
      title={"HackMidwest Connect 4 Challenge"}
      start={"2024-09-28T12:00:00"}
      end={"2024-09-29T12:00:00"}
      logo={HackMidwestLogo}
      winners={winners}
      prizes={<Prizes />}
      results={<TournamentResults rounds={rounds} finalResults={finals} />}
    />
  );
};

const Prizes = () => {
  return (
    <div className="p-4">
      <div className="flex flex-col gap-6">
        <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
          Prizes
        </p>
        <div className="flex flex-wrap gap-4">
          <div className="bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark border-[1px] flex flex-col items-center gap-4 rounded-lg p-6">
            <div className="w-full p-4 sm:px-16 sm:py-12 rounded-lg bg-surface-light">
              <img
                src={Airpods}
                className="w-full h-auto max-h-24"
                alt="Airpods"
              />
            </div>
            <p className="text-lg font-medium text-onSurface dark:text-onSurface-dark">
              1st Place
            </p>
            <p className="text-onSurface dark:text-onSurface-dark">Airpods</p>
          </div>

          <div className="bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark border-[1px] flex flex-col items-center gap-4 rounded-lg p-6">
            <div className="w-full p-4 sm:px-12 sm:py-14 rounded-lg bg-surface-light">
              <img
                src={LogitechKeyboard}
                className="w-full h-auto max-h-20"
                alt="Logitech Keyboard"
              />
            </div>
            <p className="text-lg font-medium text-onSurface dark:text-onSurface-dark">
              2nd Place
            </p>
            <p className="text-onSurface dark:text-onSurface-dark">Keyboard</p>
          </div>

          <div className="bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark border-[1px] flex flex-col items-center gap-4 rounded-lg p-6">
            <div className="w-full p-4 sm:px-16 sm:py-12 rounded-lg bg-surface-light">
              <img
                src={EchoDot}
                className="w-full h-auto max-h-24"
                alt="Echo Dot"
              />
            </div>
            <p className="text-lg font-medium text-onSurface dark:text-onSurface-dark">
              3rd Place
            </p>
            <p className="text-onSurface dark:text-onSurface-dark">Echo Dot</p>
          </div>

          <div className="bg-surfaceContain-light border-border-light border-[1px] flex flex-col items-center gap-4 rounded-lg p-6">
            <div className="w-full p-4 sm:px-16 sm:py-12 rounded-lg bg-surface-light">
              <img src={Kirby} className="w-full h-auto max-h-24" alt="Kirby" />
            </div>
            <p className="text-lg font-medium text-onSurface dark:text-onSurface-dark">
              Special Prize!
            </p>
            <p className="text-onSurface dark:text-onSurface-dark">
              Kirby Plushie 💗
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HackMidwest;
