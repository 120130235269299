import React, { useState } from "react";

const NewBotModal = ({ isOpen, onClose, onCreateBot }) => {
  const [botName, setBotName] = useState("");
  const [error, setError] = useState("");

  const handleCreate = () => {
    if (
      !botName ||
      botName.length < 2 ||
      botName.length > 12 ||
      botName.includes(" ")
    ) {
      setError("Bot name must be 2-12 characters without spaces");
      return;
    }

    onCreateBot(botName);
    setBotName("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="flex justify-center text-xl mb-4">Name Your New Bot</h2>
        <input
          type="text"
          value={botName}
          onChange={(e) => {
            setBotName(e.target.value);
            setError("");
          }}
          placeholder="Enter bot name"
          className="border rounded px-2 py-1 w-full mb-2"
          autoFocus
        />
        {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => {
              setBotName("");
              onClose();
            }}
            className="bg-gray-200 text-black px-4 py-2 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleCreate}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Create Bot
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewBotModal;
