import React from "react";
import WorkingBrain from "../Images/Brains/working.png";

function Building() {
  return (
    <div className="flex flex-col gap-4 items-center justify-center mt-20">
      <img src={WorkingBrain} alt="Loading" className="h-40" />
      <p className="text-lg font-semibold text-onSurface-light dark:text-onSurface-dark">
        Coming soon...
      </p>
    </div>
  );
}

export default Building;
