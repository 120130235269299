import React, { useState, useEffect } from "react";
import { auth, signOut } from "../Auth/FirebaseAuth";
import { emojiMap } from "../Constants/Icons";
import Loading from "../Components/Loading";
import UserBots from "../Components/UserBots";
import { getUserInfo, postUserInfo } from "../API/User";

const Profile = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [editing, setEditing] = useState(false); // To toggle edit mode
  const [formData, setFormData] = useState({
    name: "",
    school: "",
    company: "",
  });

  useEffect(() => {
    getUserInfo(user)
      .then((res) => {
        setUserInfo(res);
        setFormData({
          name: res.name || "",
          school: res.school || "",
          company: res.company || "",
        });
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const saveChanges = async () => {
    postUserInfo(user, formData)
      .then((res) => {
        setUserInfo((prev) => ({
          ...prev,
          name: res.name,
          school: res.school,
          company: res.company,
        }));
        setFormData({
          name: res.name || "",
          school: res.school || "",
          company: res.company || "",
        });
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setEditing(false);
      });
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) {
    return (
      <div className="h-screen p-32">
        <Loading />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center py-10">
      {/* Profile Header */}
      <div className="bg-gray-50 rounded-lg border-[1px] p-6 w-full max-w-4xl px-4">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-6">
          <div className="flex flex-col sm:flex-row gap-4 items-center">
            <div className="w-24 h-24 bg-primary rounded-full flex items-center justify-center text-white text-6xl font-bold">
              {emojiMap[userInfo.icon]}
            </div>
            <div className="text-center sm:text-left">
              {editing ? (
                <div className="flex flex-col gap-2">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="text-xl border-b border-gray-300 outline-none focus:border-primary"
                    placeholder="Full Name"
                  />
                  <input
                    type="text"
                    name="school"
                    value={formData.school}
                    onChange={handleInputChange}
                    className="text-sm text-gray-600 border-b border-gray-300 outline-none focus:border-primary mt-2"
                    placeholder="School"
                  />
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    className="text-sm text-gray-600 border-b border-gray-300 outline-none focus:border-primary mt-2"
                    placeholder="Company"
                  />
                </div>
              ) : (
                <>
                  <h1 className="text-2xl font-semibold">
                    {userInfo.name || "Anonymous User"}
                  </h1>
                  <p className="text-gray-500">@{userInfo.username}</p>
                  <div className="mt-2 flex flex-col gap-[1px]">
                    <p className="text-sm text-gray-600">
                      Rating:{" "}
                      <span className="font-bold">{userInfo.rating}</span>
                    </p>
                    <p className="text-sm text-gray-600">Email: {user.email}</p>
                    <p className="text-sm text-gray-600">
                      School: {userInfo.school || "N/A"}
                    </p>
                    <p className="text-sm text-gray-600">
                      Company: {userInfo.company || "N/A"}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {/* Edit/Save Buttons */}
            <div>
              {editing ? (
                <div className="flex flex-row gap-2">
                  <button
                    className="bg-gray-300 text-black px-4 py-2 rounded-md hover:shadow-md hover:scale-[1.03] duration-100 transition-all"
                    onClick={() => setEditing(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-primary text-white px-4 py-2 rounded-md hover:shadow-md hover:scale-[1.03] duration-100 transition-all"
                    onClick={saveChanges}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <button
                  className="bg-primary text-white px-4 py-2 rounded-md hover:shadow-md hover:scale-[1.03] duration-100 transition-all"
                  onClick={() => setEditing(true)}
                >
                  edit profile
                </button>
              )}
            </div>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:shadow-md hover:scale-[1.03] duration-100 transition-all"
              onClick={logout}
            >
              logout
            </button>
          </div>
        </div>
      </div>
      <UserBots bots={userInfo.bots} />
    </div>
  );
};

export default Profile;
