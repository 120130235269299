import JSZip from "jszip";

export async function decodeZipString(files) {
  if (!files) {
    return "user is not authorized to view code";
  }

  const binaryData = atob(files);
  const binaryArray = Uint8Array.from(binaryData, (char) => char.charCodeAt(0));

  const zip = new JSZip();

  try {
    const zipContent = await zip.loadAsync(binaryArray);
    const fileContent = await zipContent.file("player.py").async("string");
    return fileContent;
  } catch (error) {
    console.error("Error extracting code:", error);
    return "Error extracting code";
  }
}

export async function decodeAllZips(files) {
  if (!files) {
    return "user is not authorized to view code";
  }

  const binaryData = atob(files);
  const binaryArray = Uint8Array.from(binaryData, (char) => char.charCodeAt(0));

  const zip = new JSZip();

  try {
    const zipContent = await zip.loadAsync(binaryArray);

    const fileContents = {};
    for (const fileName of Object.keys(zipContent.files)) {
      const file = zipContent.file(fileName);

      if (file) {
        fileContents[fileName] = file;
        // console.log(fileName);
        // console.log(file);
      }
    }

    return fileContents;
  } catch (error) {
    console.error("Error extracting code:", error);
    return { error: "Error extracting code" };
  }
}
