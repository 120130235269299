import React, { useState } from "react";
import { contestDetails } from "../Constants/Contests";
import { useNavigate } from "react-router-dom";
import { GamesInfo } from "../Constants/Games";
import { getContestStatus } from "../Constants/Contests";
import SearchBar from "../Components/SearchBar";

export default function Home() {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row p-4 gap-4">
        <div className="w-full">
          <GameMenu />
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-2xl text-center py-4">featured contests</div>
          <div className="flex flex-col justify-center items-center gap-8 px-2">
            {contestDetails.map((contest, index) => (
              <div key={index}>
                <FeaturedContestCard contest={contest} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function GameMenu() {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredGames = Object.keys(GamesInfo).filter((game) =>
    game
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(searchTerm.toLowerCase().replace(/\s+/g, ""))
  );
  return (
    <div className="flex flex-col items-center gap-12 px-4 py-4">
      <div className="flex flex-col max-w-full gap-8 sm:w-full 2xl:max-w-screen-xl xl:max-w-screen-lg lg:max-w-screen-md md:max-w-screen-sm">
        <SearchBar setSearchTerm={setSearchTerm} />

        <div className="grid justify-center w-full grid-cols-1 gap-4 xl:grid-cols-2 2xl:grid-cols-3 lg:justify-start">
          {filteredGames.length > 0 ? (
            filteredGames.map((game, index) => (
              <GameCard key={index} game={game} />
            ))
          ) : (
            <p className="text-lg text-onSurfaceSecond">No games found</p>
          )}
        </div>
      </div>
    </div>
  );
}

function GameCard({ game }) {
  const navigate = useNavigate();

  return (
    <div className="p-6 rounded-lg bg-gray-50 border-[1px] w-full shadow-md hover:shadow-lg hover:scale-[1.03] duration-200 transition-all ease-linear">
      {/* Card Container */}
      <div className="flex justify-between items-center w-full gap-6">
        <div className="flex flex-col lg:flex-row gap-4 items-center">
          <img
            src={GamesInfo[game].image}
            alt={game}
            className="rounded-lg w-24 h-24"
          />
          {/* Card Content Container */}
          <div className="flex items-center justify-center">
            <div className="text-xl font-normal leading-none">
              {GamesInfo[game].title}
            </div>
          </div>
        </div>
        {GamesInfo[game].active ? (
          <div className="flex flex-col items-center gap-4">
            {/* Play Button */}
            <button
              onClick={() => navigate(`/arena/${game}`)}
              className="flex items-center justify-center bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 12l-6 4V8l6 4z"
                />
              </svg>
              play
            </button>

            <button
              onClick={() => navigate(`/editor/${game}`)}
              className="flex items-center justify-center bg-gray-700 text-white px-4 py-2 gap-2 rounded-lg shadow hover:bg-gray-800 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon-code"
                width="24"
                height="24"
              >
                <path d="M16 18l6-6-6-6" />
                <path d="M8 6L2 12l6 6" />
              </svg>
              code
            </button>
          </div>
        ) : (
          <div>coming soon!</div>
        )}
      </div>
    </div>
  );
}

function FeaturedContestCard({ contest }) {
  const info = getContestStatus(contest.startDate, contest.endDate);

  const navigate = useNavigate();

  return (
    <div>
      <div
        onClick={() => navigate(contest.navigate)}
        className="flex flex-row shadow-md hover:shadow-lg rounded-lg text-center w-96 h-32 transform transition-transform duration-200 hover:scale-105 cursor-pointer"
      >
        <div className="relative rounded-l-lg">
          <img
            className="h-32 rounded-l-lg"
            src={contest.image}
            alt={`${contest.title} logo`}
          />
          <img
            className="absolute bottom-2 left-2 w-8 h-8 rounded-md"
            src={GamesInfo[contest.game].image}
            alt="Game logo"
          />
        </div>

        <div className="flex flex-col justify-between gap-2 rounded-r-lg bg-gray-100 w-2/3">
          <div className="text-2xl font-semibold pt-5 px-6 rounded-r-lg">
            {contest.title}
          </div>
          <div
            className={`text-sm font-semibold py-1 rounded-r-lg
                          ${
                            info.status === "Upcoming"
                              ? "bg-yellow-200 text-yellow-800"
                              : info.status === "Expired"
                              ? "bg-gray-200 text-gray-800"
                              : "bg-green-200 text-green-800"
                          }
                      `}
          >
            {info.status === "Expired" ? "View Results" : info.status}
          </div>
        </div>
      </div>
    </div>
  );
}
