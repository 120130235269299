import React, { useState, useEffect } from "react";
import { emojiMap } from "../Constants/Icons";

const ContestDetails = ({
  title,
  start,
  end,
  logo,
  winners,
  prizes,
  results,
}) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeToStart = startDate - now;
    const timeToEnd = endDate - now;

    if (timeToStart > 0) {
      return { status: "starting", timeLeft: timeToStart };
    } else if (timeToEnd > 0) {
      return { status: "active", timeLeft: timeToEnd };
    } else {
      return { status: "ended", timeLeft: null };
    }
  };

  const [timeStatus, setTimeStatus] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeStatus(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  return (
    <div className="flex flex-col items-center gap-6 px-8 py-8 md:px-10">
      <div className="flex flex-col w-full 2xl:max-w-screen-xl xl:max-w-screen-lg lg:max-w-screen-md gap-2">
        <div className="flex justify-between items-center">
          <div className="text-4xl font-bold text-onSurface-light dark:text-onSurface-dark">
            {title}
          </div>
          <img className="w-20" src={logo} />
        </div>

        {timeStatus.status === "starting" && (
          <div className="bg-yellow-100 text-yellow-700 py-4 px-6 mt-6 rounded-md">
            <span>The contest will start in </span>
            <span>{`${formatTime(timeStatus.timeLeft).days}d ${
              formatTime(timeStatus.timeLeft).hours
            }h ${formatTime(timeStatus.timeLeft).minutes}m ${
              formatTime(timeStatus.timeLeft).seconds
            }s`}</span>
          </div>
        )}

        {timeStatus.status === "active" && (
          <div className="bg-green-100 text-green-700 py-4 px-6 mt-6 rounded-md">
            <span>This contest will end in </span>
            <span>{`${formatTime(timeStatus.timeLeft).days}d ${
              formatTime(timeStatus.timeLeft).hours
            }h ${formatTime(timeStatus.timeLeft).minutes}m ${
              formatTime(timeStatus.timeLeft).seconds
            }s`}</span>
          </div>
        )}

        {timeStatus.status === "ended" && (
          <div>
            <div className="bg-gray-100 text-gray-700 py-4 px-6 mt-6 rounded-md">
              <span>This contest has ended.</span>
            </div>
            <div className="mt-6">
              <div className="p-8 rounded-lg shadow-lg bg-gray-100 dark:bg-gray-900 text-onSurface-light dark:text-onSurface-dark">
                <TopWinners winners={winners} />
                {results}
                {prizes}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const TopWinners = ({ winners }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center space-x-8 m-1">
      {/* 2nd Place */}
      <div className="flex flex-col items-center">
        <div
          className={`${winners[1].bgColor} text-white flex justify-center items-center w-16 h-16 md:w-20 md:h-20 rounded-full text-3xl font-bold shadow-lg transform hover:scale-110 transition-transform duration-300 ease-out`}
        >
          {emojiMap[winners[1].emoji]}
        </div>
        <p className="mt-2 text-md md:text-lg text-gray-700 font-semibold">
          {winners[1].name}
        </p>
        <p className="text-sm md:text-gray-500 italic font-light">
          {winners[1].username}
        </p>
        <p className="text-sm md:text-md text-gray-500">
          {winners[1].position}
        </p>
      </div>

      {/* 1st Place */}
      <div className="flex flex-col items-center top-[-1px]">
        <div
          className={`${winners[0].bgColor} text-white flex justify-center items-center w-20 h-20 md:w-24 md:h-24 rounded-full text-4xl font-bold shadow-xl transform hover:scale-125 transition-transform duration-300 ease-out`}
        >
          {emojiMap[winners[0].emoji]}
        </div>
        <p className="mt-3 text-lg md:text-xl text-gray-700 font-bold">
          {winners[0].name}
        </p>
        <p className="text-sm md:text-gray-500 italic font-light">
          {winners[0].username}
        </p>
        <p className="text-md md:text-lg text-gray-500">
          {winners[0].position}
        </p>
      </div>

      {/* 3rd Place */}
      <div className="flex flex-col items-center">
        <div
          className={`${winners[2].bgColor} text-white flex justify-center items-center w-16 h-16 md:w-20 md:h-20 rounded-full text-3xl font-bold shadow-lg transform hover:scale-110 transition-transform duration-300 ease-out`}
        >
          {emojiMap[winners[2].emoji]}
        </div>
        <p className="mt-2 text-md md:text-lg text-gray-700 font-semibold">
          {winners[2].name}
        </p>
        <p className="text-sm md:text-gray-500 italic font-light">
          {winners[2].username}
        </p>
        <p className="text-sm md:text-md text-gray-500">
          {winners[2].position}
        </p>
      </div>
    </div>
  );
};

export default ContestDetails;
