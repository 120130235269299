import React from "react";
import { emojiMap } from "../Constants/Icons";

const ModelInfo = ({ Wins, Rating, Name, Creator, Icon, DateCreated }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-2 rounded-lg justify-between w-full p-6 bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark">
      <div className="flex flex-col sm:flex-row gap-6">
        {Rating && (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
              Rating
            </p>
            <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
              {Rating}
            </p>
          </div>
        )}
        {Wins && (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
              Wins
            </p>
            <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
              {Wins}
            </p>
          </div>
        )}
        {Name && (
          <div className="flex flex-col gap-1 break-all">
            <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
              Name
            </p>
            <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
              {Name}
            </p>
          </div>
        )}
        {Creator && (
          <div className="flex flex-col gap-1 break-all">
            <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
              Creator
            </p>
            <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
              {Creator} {Icon && emojiMap[Icon]}
            </p>
          </div>
        )}
      </div>
      {DateCreated && (
        <div className="flex flex-col gap-1 mt-4 sm:mt-0">
          <p className="text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
            Last Updated
          </p>
          <p className="text-xl font-medium text-onSurface-light dark:text-onSurface-dark">
            {DateCreated}
          </p>
        </div>
      )}
    </div>
  );
};

export default ModelInfo;
