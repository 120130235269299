import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { emojiMap } from "../Constants/Icons";
import Winner from "../Images/Brains/winner.png";
import Loser from "../Images/Brains/loser.png";
import { GamesInfo } from "../Constants/Games";

const BattlePopup = ({ bot1, bot2, user, game, onClose }) => {
  const navigate = useNavigate();

  const [showWinner, setShowWinner] = useState(false);
  const [winner, setWinner] = useState(0);
  const [timeout, setTimeoutFlag] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [error, setError] = useState(false);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative max-w-4xl w-full md:w-[90%] bg-white rounded-lg shadow-lg p-4 md:p-6 overflow-auto max-h-[90vh]">
        <div className="flex justify-between items-center p-2 border rounded-lg">
          <button
            onClick={onClose}
            className="flex items-center gap-2 text-lg text-gray-700 hover:text-blue-600 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Back
          </button>
          <div className="text-xl font-semibold text-gray-800 text-center">
            {GamesInfo[game].title}
          </div>
          <img
            src={GamesInfo[game].image}
            alt={game}
            className="rounded-lg w-8 sm:w-12 sm:h-12"
          />
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-6 mt-4">
          <div className="md:hidden flex justify-center items-center p-2 mb-4">
            <span
              onClick={() => navigate(`/bots/${bot1.game}/${bot1.botId}`)}
              className="font-semibold underline cursor-pointer text-blue-500"
            >
              {bot1.botname}
            </span>
            {bot1 && bot2 && <span className="mx-2 text-gray-500">vs</span>}
            <span
              onClick={() => navigate(`/bots/${bot2.game}/${bot2.botId}`)}
              className="font-semibold underline cursor-pointer text-red-500"
            >
              {bot2.botname}
            </span>
          </div>

          {/* For larger screens, keep the player 1 and player 2 details */}
          <div className="hidden md:flex flex-col items-center text-center justify-center border p-4 gap-4 rounded-lg w-full md:w-48">
            {showWinner ? (
              <img
                src={winner === 1 ? Winner : Loser}
                alt={winner === 1 ? "Winner" : "Loser"}
                className="w-16 h-16 animate-bounce"
              />
            ) : (
              <div className="w-16 h-16"></div>
            )}
            <p className="text-4xl">{emojiMap[bot1.usericon]}</p>
            <h2 className="text-lg font-bold">{bot1.botname}</h2>
            <p className="text-sm italic break-all">@{bot1.username}</p>
          </div>
          <div className="flex flex-col items-center justify-center border p-4 md:p-6 rounded-lg flex-1">
            {showWinner && (
              <div className="text-center">
                {timeout && (
                  <div className="text-red-500">This code timed out!</div>
                )}
                {invalid && <div className="text-red-500">Invalid move!</div>}
                {error && (
                  <div className="text-red-500">An error occurred!</div>
                )}
                {winner === 1 && <p>{bot1.botname + " won!"}</p>}
                {winner === 2 && <p>{bot2.botname + " won!"}</p>}
                {winner === 3 && <p>It's a draw!</p>}
              </div>
            )}
            {GamesInfo[game].battleView(
              bot1,
              bot2,
              user,
              setShowWinner,
              setWinner,
              setInvalid,
              setTimeoutFlag,
              setError
            )}
          </div>

          <div className="hidden md:flex flex-col items-center text-center justify-center border p-4 gap-4 rounded-lg w-full md:w-48">
            {showWinner ? (
              <img
                src={winner === 2 ? Winner : Loser}
                alt={winner === 2 ? "Winner" : "Loser"}
                className="w-16 h-16 animate-bounce"
              />
            ) : (
              <div className="w-16 h-16"></div>
            )}
            <p className="text-4xl">{emojiMap[bot2.usericon]}</p>
            <h2 className="text-lg font-bold">{bot2.botname}</h2>
            <p className="text-sm italic break-all">@{bot2.username}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattlePopup;
