import React, { useState } from "react";
import { emojiMap } from "../Constants/Icons";
import Winner from "../Images/Brains/winner.png";
import Loser from "../Images/Brains/loser.png";
import { GamesInfo } from "../Constants/Games";
import { contestIcons } from "../Constants/Contests";

const GameView = ({ gameData, onClose, noName }) => {
  const [showWinner, setShowWinner] = useState(false);

  return (
    <div className="flex justify-center w-full p-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 justify-between sm:flex-row p-2 bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark rounded-lg">
          <div className="flex justify-center items-center">
            <button
              onClick={onClose}
              className="flex items-center gap-2 text-lg text-onSurface-light dark:text-onSurface-dark sm:text-xl hover:text-blue-600 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              Back
            </button>
          </div>
          <div className="flex justify-center items-center text-onSurface-light dark:text-onSurface-dark text-xl">
            {GamesInfo[gameData.game].title}
          </div>
          <div className="flex justify-center items-center">
            {gameData.contestId ? (
              <img
                src={contestIcons[gameData.contestId]}
                alt={"contest icon"}
                className="rounded-lg w-8 sm:w-12 sm:h-12"
              />
            ) : (
              <img
                src={GamesInfo[gameData.game].image}
                alt={gameData.game}
                className="rounded-lg w-8 sm:w-12 sm:h-12"
              />
            )}
          </div>
        </div>

        {/* Player 1 Info, Game View, and Player 2 Info */}
        <div className="flex flex-col justify-center sm:justify-between gap-4 sm:flex-row">
          {/* Player 1 Info */}
          <div className="flex flex-col items-center justify-center sm:flex-1 p-2 text-center break-all bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark rounded-lg w-48">
            {showWinner && (
              <div>
                {gameData.winner === 1 ? (
                  <img
                    src={Winner}
                    alt="Winner"
                    className="w-24 h-24 p-4 animate-bounce sm:w-32 sm:h-32"
                  />
                ) : (
                  <img
                    src={Loser}
                    alt="Loser"
                    className="w-24 h-24 p-4 animate-pulse sm:w-32 sm:h-32"
                  />
                )}
              </div>
            )}
            <p className="text-3xl sm:text-4xl text-onSurface-light dark:text-onSurface-dark">
              {emojiMap[gameData.player1Icon]}
            </p>
            <h2 className="text-lg font-bold sm:text-xl text-onSurface-light dark:text-onSurface-dark">
              {gameData.player1ModelName}
            </h2>
            <p className="text-sm italic text-onSurface-light dark:text-onSurface-dark">
              {gameData.player1Username}
            </p>
            <p className="font-semibold text-onSurface-light dark:text-onSurface-dark">
              {gameData.player1EloBefore} → {gameData.player1EloAfter}
            </p>
            <p
              className={`text-sm ${
                gameData.player1EloAfter - gameData.player1EloBefore > 0
                  ? "text-green-600 dark:text-green-400"
                  : "text-red-600 dark:text-red-400"
              }`}
            >
              {gameData.player1EloAfter - gameData.player1EloBefore > 0
                ? `+${gameData.player1EloAfter - gameData.player1EloBefore}`
                : gameData.player1EloAfter - gameData.player1EloBefore}
            </p>
            {Object.keys(GamesInfo[gameData.game].visualMap).length > 0 && (
              <div className="flex items-center justify-center w-8 h-8 mt-4 rounded-lg bg-white sm:w-10 sm:h-10">
                {GamesInfo[gameData.game].visualMap[1]}
              </div>
            )}
          </div>

          {/* Game View */}
          <div className="flex flex-col items-center justify-center sm:flex-1 p-8 bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark rounded-lg">
            <div>
              {gameData.codebroke === 1 && (
                <div className="p-2 text-center text-red-500">
                  {(noName
                    ? gameData.player1Username
                    : gameData.player1ModelName) + "'s code broke!"}
                </div>
              )}
              {gameData.codebroke === 2 && (
                <div className="p-2 text-center text-red-500">
                  {(noName
                    ? gameData.player2Username
                    : gameData.player2ModelName) + "'s code broke!"}
                </div>
              )}
              {gameData.codebroke === 3 && (
                <div className="p-2 text-center text-red-500">
                  {"Both players code broke!"}
                </div>
              )}
              {gameData.invalid === 1 && (
                <div className="p-2 text-center text-red-500">
                  {(noName
                    ? gameData.player1Username
                    : gameData.player1ModelName) + " made an invalid move!"}
                </div>
              )}
              {gameData.invalid === 2 && (
                <div className="p-2 text-center text-red-500">
                  {(noName
                    ? gameData.player2Username
                    : gameData.player2ModelName) + " made an invalid move!"}
                </div>
              )}
              {gameData.winner === 0 ||
                (gameData.winner === 3 && (
                  <div className="p-2 text-center text-green-500">
                    It's a draw!
                  </div>
                ))}
              {gameData.winner === 1 && (
                <div className="p-2 text-center text-green-500">
                  {(noName
                    ? gameData.player1Username
                    : gameData.player1ModelName) + " won!"}
                </div>
              )}
              {gameData.winner === 2 && (
                <div className="p-2 text-center text-green-500">
                  {(noName
                    ? gameData.player2Username
                    : gameData.player2ModelName) + " won!"}
                </div>
              )}
              {gameData.timeout === 1 && (
                <div className="p-2 text-center text-red-500">
                  {(noName
                    ? gameData.player1Username
                    : gameData.player1ModelName) + " timed out!"}
                </div>
              )}
              {gameData.timeout === 2 && (
                <div className="p-2 text-center text-red-500">
                  {(noName
                    ? gameData.player2Username
                    : gameData.player2ModelName) + " timed out!"}
                </div>
              )}
            </div>
            {GamesInfo[gameData.game].gameView(gameData, setShowWinner)}
          </div>

          {/* Player 2 Info */}
          <div className="flex flex-col items-center justify-center sm:flex-1 p-2 text-center break-all bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark rounded-lg w-48">
            {showWinner && (
              <div>
                {gameData.winner === 2 ? (
                  <img
                    src={Winner}
                    alt="Winner"
                    className="w-24 h-24 p-4 animate-bounce sm:w-32 sm:h-32"
                  />
                ) : (
                  <img
                    src={Loser}
                    alt="Loser"
                    className="w-24 h-24 p-4 animate-pulse sm:w-32 sm:h-32"
                  />
                )}
              </div>
            )}
            <p className="text-3xl sm:text-4xl dark:text-onSurface-dark text-onSurface-light">
              {emojiMap[gameData.player2Icon]}
            </p>
            <h2 className="text-lg font-bold sm:text-xl dark:text-onSurface-dark text-onSurface-light">
              {gameData.player2ModelName}
            </h2>
            <p className="text-sm italic dark:text-onSurface-dark text-onSurface-light">
              {gameData.player2Username}
            </p>
            <p className="font-semibold dark:text-onSurface-dark text-onSurface-light">
              {gameData.player2EloBefore} → {gameData.player2EloAfter}
            </p>
            <p
              className={`text-sm ${
                gameData.player2EloAfter - gameData.player2EloBefore > 0
                  ? "text-green-600 dark:text-green-400"
                  : "text-red-600 dark:text-red-400"
              }`}
            >
              {gameData.player2EloAfter - gameData.player2EloBefore > 0
                ? `+${gameData.player2EloAfter - gameData.player2EloBefore}`
                : gameData.player2EloAfter - gameData.player2EloBefore}
            </p>
            {Object.keys(GamesInfo[gameData.game].visualMap).length > 0 && (
              <div className="flex items-center justify-center w-8 h-8 mt-4 rounded-lg bg-white sm:w-10 sm:h-10">
                {GamesInfo[gameData.game].visualMap[2]}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameView;
