export const getGameBots = async (game) => {
  const jsonData = { game: game };
  const res = await fetch(process.env.REACT_APP_endpointURL + "/game/bots", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonData),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};
