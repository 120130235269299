import React from "react";
import Pinata from "../Images/Logos/pinata.webp";
import TournamentResults from "../Components/Results";
import rounds from "../ContestData/pinata2024rounds.json";
import finalResults from "../ContestData/pinata2024finals.json";
import ContestDetails from "../Pages/ContestDetails";

const PinataChallenge = () => {
  const finals = finalResults
    .sort((a, b) => b.wins - a.wins)
    .map((entry, index) => ({
      ...entry,
      rank: index + 1,
    }));

  const winners = [
    {
      name: "Jacob Dimmitt",
      username: "@fomo_quandale_dingle430",
      emoji: 78,
      position: "1st",
      bgColor: "bg-gradient-to-r from-yellow-400 to-yellow-600",
    },
    {
      name: "Luke Zhu",
      username: "@better_call_saul_shimr952",
      emoji: 9,
      position: "2nd",
      bgColor: "bg-gradient-to-r from-blue-400 to-blue-600",
    },
    {
      name: "Madhavi Prakash",
      username: "@cg5_sin_city725",
      emoji: 65,
      position: "3rd",
      bgColor: "bg-gradient-to-r from-orange-400 to-orange-600",
    },
  ];

  return (
    <ContestDetails
      title={"Pinata Chess Challenge"}
      start={"2024-11-17T12:00:00"}
      end={"2024-11-23T12:00:00"}
      logo={Pinata}
      winners={winners}
      prizes={<Prizes />}
      results={
        <TournamentResults
          rounds={rounds}
          finalResults={finals}
          noName={true}
          showCode={true}
        />
      }
    />
  );
};

const Prizes = () => {
  return (
    <div className="flex flex-col text-center p-2 mt-8">
      <div className="text-4xl font-semibold text-onSurface-light dark:text-onSurface-dark">
        Prizes
      </div>
      <div className="flex flex-col sm:flex-row justify-between w-full mt-6 pb-16 gap-4 text-onSurface-light">
        <div className="flex flex-col w-full items-center bg-yellow-200 p-6 rounded-lg shadow-md">
          <div className="text-2xl font-semibold">1st Place</div>
          <div className="mt-4 text-xl">$1,000</div>
        </div>
        <div className="flex flex-col w-full items-center bg-gray-200 p-6 rounded-lg shadow-md">
          <div className="text-2xl font-semibold">2nd Place</div>
          <div className="mt-4 text-xl">$750</div>
        </div>
        <div className="flex flex-col w-full items-center bg-orange-200 p-6 rounded-lg shadow-md">
          <div className="text-2xl font-semibold">3rd Place</div>
          <div className="mt-4 text-xl">$250</div>
        </div>
      </div>
    </div>
  );
};

export default PinataChallenge;
