export const getUserInfo = async (user) => {
  const token = await user.getIdToken();
  const jsonData = { uid: user.uid };
  const res = await fetch(process.env.REACT_APP_endpointURL + "/user/info", {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonData),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const getUserInfoByUID = async (uid) => {
  const jsonData = { uid: uid };
  const res = await fetch(process.env.REACT_APP_endpointURL + "/user/info", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonData),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const getUserIcon = async (uid) => {
  const jsonData = { uid: uid };
  const res = await fetch(
    process.env.REACT_APP_endpointURL + "/user/info/icon",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonData),
    }
  );
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const getUserInfoByUsername = async (username) => {
  const jsonData = { username: username };
  const res = await fetch(process.env.REACT_APP_endpointURL + "/user/profile", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonData),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const getUserLeaderboard = async () => {
  const res = await fetch(
    process.env.REACT_APP_endpointURL + "/user/leaderboard",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const postUserInfo = async (user, info) => {
  const token = await user.getIdToken();
  const jsonData = {
    name: info.name,
    company: info.company,
    school: info.school,
  };
  const res = await fetch(process.env.REACT_APP_endpointURL + "/user/create", {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonData),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const postUserBots = async (user, game) => {
  const res = await fetch(process.env.REACT_APP_endpointURL + "/user/bots", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: await user.getIdToken(),
    },
    body: JSON.stringify({
      uid: user.uid,
      game: game,
    }),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};
