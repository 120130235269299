import React, { useState, useEffect } from "react";

export const Connect4BattleView = ({
  player1,
  player2,
  user,
  setShowWinner,
  setWinner,
  setInvalid,
  setTimeoutFlag,
  setError,
}) => {
  const emptyBoard = [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
  ];

  const [moves, setMoves] = useState([emptyBoard]);
  const [gameEnd, setGameEnd] = useState(false);
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState("Connecting...");

  const board = moves[currentMoveIndex];

  useEffect(() => {
    let socket = null;

    const initializeWebSocket = async () => {
      const token = await user.getIdToken();
      socket = new WebSocket(`${process.env.REACT_APP_endpointURL}/game/match`);

      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            user: token,
            "bot1-ID": player1.botId,
            "bot2-ID": player2.botId,
            game: "connect4",
          })
        );
        console.log("WebSocket connection established");
        setConnectionStatus("Connected");
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.move && !data.invalid) {
            setMoves((prev) => [...prev, JSON.parse(data.state)]);
          }

          if (data.winner) {
            setGameEnd(true);
            setWinner(data.winner);
          }
          if (data.exception) setError(true);
          if (data.invalid) setInvalid(true);
          if (data.timeout) setTimeoutFlag(true);
        } catch (err) {
          console.error("Error parsing WebSocket message:", err);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setConnectionStatus("Error");
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
        setConnectionStatus("Disconnected");
      };
    };

    // Initialize the WebSocket connection
    initializeWebSocket();

    // Cleanup function to close the WebSocket when the component is unmounted
    return () => {
      if (socket) {
        console.log("Closing WebSocket connection...");
        socket.close();
      }
    };
  }, [user, player1, player2]);

  const stepForward = () => {
    if (currentMoveIndex < moves.length) {
      if (currentMoveIndex + 1 === moves.length - 1 && gameEnd) {
        setShowWinner(true);
      }
      setCurrentMoveIndex((prev) => prev + 1);
    }
  };

  const stepBackward = () => {
    if (currentMoveIndex > 0) {
      setCurrentMoveIndex((prev) => prev - 1);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="grid bg-blue-600 rounded-lg p-2">
        {board
          .map((_, rowIndex) => board[board.length - 1 - rowIndex])
          .map((row, rowIndex) => (
            <div key={rowIndex} className="flex">
              {row.map((cell, colIndex) => {
                return (
                  <div
                    key={colIndex}
                    className="relative flex items-center justify-center w-10 h-10 rounded-lg"
                  >
                    <div
                      className={`w-8 h-8 rounded-full transform transition-all duration-500 ease-in-out ${
                        cell === 1
                          ? "bg-red-500" // Player 1 (Red)
                          : cell === 2
                          ? "bg-yellow-400" // Player 2 (Yellow)
                          : "bg-gray-300" // Empty space
                      }`}
                    ></div>
                  </div>
                );
              })}
            </div>
          ))}
      </div>
      <div className="flex flex-row justify-center gap-2 p-2 pt-4">
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={stepBackward}
          disabled={currentMoveIndex === 0}
        >
          Previous
        </button>
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={stepForward}
          disabled={currentMoveIndex === moves.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
};
