export const getBotDetails = async (user, game, botId) => {
  const token = await user.getIdToken();
  const jsonData = { game: game, botId: botId };
  const res = await fetch(process.env.REACT_APP_endpointURL + "/bot/info", {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonData),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const getBotMove = async (user, game, botId, gameState, turn) => {
  const token = await user.getIdToken();
  const jsonData = {
    game: game,
    botId: botId,
    gamestate: gameState,
    turn: turn,
  };
  const res = await fetch(process.env.REACT_APP_endpointURL + "/bot/move", {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonData),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const postBotCreate = async (user, name, game, files) => {
  const res = await fetch(process.env.REACT_APP_endpointURL + "/bot/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: await user.getIdToken(),
    },
    body: JSON.stringify({
      uid: user.uid,
      name: name,
      game: game,
      files: files,
    }),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};

export const postBotEdit = async (user, botId, game, files) => {
  const res = await fetch(process.env.REACT_APP_endpointURL + "/bot/edit", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: await user.getIdToken(),
    },
    body: JSON.stringify({
      uid: user.uid,
      botId: botId,
      game: game,
      files: files,
    }),
  });
  if (!res.ok) {
    throw new Error(`HTTP error! status: ${res.status}`);
  }
  const result = await res.json();
  return result;
};
