import React, { useState, useEffect } from "react";
import { getBotMove } from "../API/Bots";

export const Connect4PlayView = ({
  contestId,
  botId,
  user,
  setException,
  setStdout,
  setInvalid,
  setTimeoutFlag,
  setMoveTime,
  setMove,
}) => {
  const [playerColor, setPlayerColor] = useState("red");

  return (
    <div>
      <Connect4PlayBot
        key={playerColor} // Changing this key will force a rerender
        contestId={contestId}
        botId={botId}
        user={user}
        playerColor={playerColor}
        setException={setException}
        setStdout={setStdout}
        setTimeout={setTimeoutFlag}
        setInvalid={setInvalid}
        setMoveTime={setMoveTime}
        setMove={setMove}
      />
      <div className="flex justify-center">
        <button
          className="bg-primary text-white rounded-lg p-2 px-4 shadow-md"
          onClick={() =>
            setPlayerColor(playerColor === "red" ? "yellow" : "red")
          }
        >
          play as {playerColor === "red" ? "yellow" : "red"}
        </button>
      </div>
    </div>
  );
};

const Connect4PlayBot = ({
  botId,
  user,
  setException,
  setStdout,
  playerColor,
  setInvalid,
  setTimeout,
  setMoveTime,
  setMove,
}) => {
  const emptyBoard = [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
  ];
  const [board, setBoard] = useState(emptyBoard);
  const [winner, setWinner] = useState("");
  const [playableTiles, setPlayableTiles] = useState([]);

  const getMove = (turn, board) => {
    getBotMove(user, "connect4", botId, JSON.stringify(board), turn)
      .then((result) => {
        if (result.move && !result.invalid) {
          setMoveTime(result.time);
          setMove(result.move);
          setBoard((prevBoard) => {
            // Create a deep copy of the 2D array
            const newBoard = prevBoard.map((row) => [...row]);
            // Update the specific cell
            for (let i = 0; i < newBoard.length; i++) {
              if (newBoard[i][result.move] === 0) {
                newBoard[i][result.move] = playerColor === "red" ? 2 : 1;
                break;
              }
            }
            // Return the updated board
            return newBoard;
          });

          if (result.winner) {
            setPlayableTiles([]);
          } else {
            const tiles = getPlayableTiles(board);
            setPlayableTiles(tiles);
          }
        }
        if (result.winner) {
          if (result.winner === 1) {
            setWinner(playerColor === "red" ? "You won :)" : "You lost :(");
          } else if (result.winner === 2) {
            setWinner(playerColor === "yellow" ? "You won :)" : "You lost :(");
          } else {
            setWinner("It's a draw!");
          }
        }

        setStdout(result.stdout);
        setException(result.exception);

        if (result.invalid) {
          setInvalid(true);
        } else if (result.timeout) {
          setTimeout(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPlayableTiles = (board) => {
    let tiles = [];
    for (let j = 0; j < board[0].length; j++) {
      for (let i = 0; i < board.length; i++) {
        if (board[i][j] === 0) {
          tiles.push(j);
          break;
        }
      }
    }
    return tiles;
  };

  const onTileClick = (col) => {
    if (playableTiles.includes(col)) {
      setPlayableTiles([]);
      setBoard((prevBoard) => {
        // Create a deep copy of the 2D array
        const newBoard = prevBoard.map((row) => [...row]);

        // Update the specific cell
        for (let i = 0; i < newBoard.length; i++) {
          if (newBoard[i][col] === 0) {
            newBoard[i][col] = playerColor === "red" ? 1 : 2;
            break;
          }
        }

        getMove(playerColor === "red" ? "2" : "1", newBoard);

        // Return the updated board
        return newBoard;
      });
    }
  };

  useEffect(() => {
    if (playerColor === "yellow") {
      getMove("1", emptyBoard);
    } else {
      const tiles = getPlayableTiles(board);
      setPlayableTiles(tiles);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {winner ? (
        <p className="text-onSurface p-2">{winner}</p>
      ) : (
        <p className="text-onSurface p-2">play me!</p>
      )}
      <div className="grid bg-blue-600 rounded-lg p-2">
        {board
          .map((_, rowIndex) => board[board.length - 1 - rowIndex])
          .map((row, rowIndex) => (
            <div key={rowIndex} className="flex">
              {row.map((cell, colIndex) => {
                const isPlayable = playableTiles.includes(colIndex);
                return (
                  <div
                    key={colIndex}
                    className="relative flex items-center justify-center w-10 h-10 rounded-lg"
                    onClick={() => {
                      onTileClick(colIndex);
                    }}
                  >
                    <div
                      className={`w-8 h-8 rounded-full transform transition-all duration-500 ease-in-out ${
                        cell === 1
                          ? "bg-red-500" // Player 1 (Red)
                          : cell === 2
                          ? "bg-yellow-400" // Player 2 (Yellow)
                          : isPlayable
                          ? "bg-gray-300 hover:bg-blue-300 hover:animate-pulse transition ease-in-out cursor-pointer"
                          : "bg-gray-300" // Empty space
                      }`}
                    ></div>
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};
