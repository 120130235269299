import React, { useState, useEffect } from "react";
import SubmissionCode from "../Components/SubmissionCode";
import Loading from "../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../Components/NotFound";
import { GamesInfo } from "../Constants/Games";
import { getBotDetails } from "../API/Bots";
import { emojiMap } from "../Constants/Icons";
import { decodeZipString } from "../Constants/Helpers";

function BotDetails({ user }) {
  const navigate = useNavigate();

  const { game, botId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [bot, setBot] = useState({});
  const [playerCode, setPlayerCode] = useState("");
  const [move, setMove] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [timeout, setTimeout] = useState(false);
  const [moveTime, setMoveTime] = useState(0);
  const [exception, setException] = useState("");
  const [stdout, setStdout] = useState("");

  useEffect(() => {
    getBotDetails(user, game, botId)
      .then((res) => {
        setBot(res);
        decodeZipString(res.zip).then((res) => {
          setPlayerCode(res);
        });
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="h-screen mt-64">
        <Loading />
      </div>
    );
  }

  if (error) {
    return <NotFound />;
  }

  return (
    <div className="flex flex-col p-4 gap-4 justify-center items-center">
      <div className="w-full max-w-6xl flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row sm:justify-between items-center gap-4 p-4 border-[1px] rounded-lg">
          <div className="flex flex-row items-center gap-4">
            <img
              src={GamesInfo[bot.game].image}
              alt={game}
              className="rounded-lg w-12 h-12"
            />
            <h2 className="text-2xl">{bot.name}</h2>
          </div>
          <p className="text-gray-600">Rating: {bot.rating}</p>
          <div
            className="flex flex-row text-xl items-center cursor-pointer gap-2"
            onClick={() =>
              navigate(`/profile/${bot.username}`, { state: { uid: bot.uid } })
            }
          >
            <p className="text-sm font-medium italic text-blue-500 hover:underline hover:text-blue-400">
              @{bot.username}
            </p>
            {emojiMap[bot.usericon]}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 w-full">
          <div className="flex items-center justify-center p-4 border-[1px] rounded-lg">
            {GamesInfo[game].playView(
              300,
              "",
              bot.botId,
              user,
              setException,
              setStdout,
              setInvalid,
              setTimeout,
              setMoveTime,
              setMove
            )}
          </div>

          <div className="flex flex-col overflow-auto p-4 rounded-lg border-[1px] border-border bg-surfaceContain w-full">
            {timeout && (
              <div className="text-onSurface p-2">this code timed out!</div>
            )}
            {invalid && (
              <div className="text-onSurface p-2">
                this code made an invalid move!
              </div>
            )}
            <div className="text-blue-500">Move: {move}</div>
            <div className="text-blue-500">Time: {moveTime} seconds</div>
            <div className="text-blue-500">Output: {stdout}</div>
            <div className="text-red-300">{exception}</div>
          </div>
        </div>

        <div>
          <SubmissionCode code={playerCode} />
        </div>
      </div>

      {/* TODO: Display time taken to make move and memory used */}
    </div>
  );
}

export default BotDetails;
