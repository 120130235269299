import React, { useState, useEffect, useRef } from "react";
import Chessboard from "chessboardjsx";
import { Chess } from "chess.js";
import { chessStyles } from "../Games/Chess";

export const ChessBattleView = ({
  player1,
  player2,
  user,
  setShowWinner,
  setWinner,
  setInvalid,
  setTimeoutFlag,
  setError,
}) => {
  const [moves, setMoves] = useState([]);
  const [game] = useState(new Chess());
  const [position, setPosition] = useState(game.fen());
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [gameEnd, setGameEnd] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("Connecting...");
  const socketRef = useRef(null); // Use ref to store the WebSocket instance

  useEffect(() => {
    const initializeWebSocket = async () => {
      const token = await user.getIdToken();
      const socket = new WebSocket(
        `${process.env.REACT_APP_endpointURL}/game/match`
      );
      socketRef.current = socket; // Store the WebSocket instance in ref

      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            user: token,
            "bot1-ID": player1.botId,
            "bot2-ID": player2.botId,
            game: "chess",
          })
        );
        console.log("WebSocket connection established");
        setConnectionStatus("Connected");
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.move && !data.invalid) {
            setMoves((prev) => [...prev, data.move]);
          }

          if (data.winner) {
            setGameEnd(true);
            setWinner(data.winner);
          }
          if (data.exception) setError(true);
          if (data.invalid) setInvalid(true);
          if (data.timeout) setTimeoutFlag(true);
        } catch (err) {
          console.error("Error parsing WebSocket message:", err);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setConnectionStatus("Error");
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
        setConnectionStatus("Disconnected");
      };
    };

    initializeWebSocket();

    return () => {
      const socket = socketRef.current;
      if (socket && socket.readyState === WebSocket.OPEN) {
        console.log("Closing WebSocket connection...");
        socket.close(1000, "Component unmounted");
      }
    };
  }, [user, player1, player2]);

  const stepForward = () => {
    if (currentMoveIndex < moves.length) {
      const move = moves[currentMoveIndex];
      game.move({
        from: move.slice(0, 2),
        to: move.slice(2, 4),
        promotion: move.length > 4 ? move.slice(4, 5) : "q",
      });
      setPosition(game.fen());
      if (currentMoveIndex + 1 === moves.length && gameEnd) {
        setShowWinner(true);
      }
      setCurrentMoveIndex((prev) => prev + 1);
    }
  };

  const stepBackward = () => {
    if (currentMoveIndex > 0) {
      game.undo();
      setPosition(game.fen());
      setCurrentMoveIndex((prev) => prev - 1);
    }
  };

  return (
    <div className="flex flex-col">
      <Chessboard
        width={350}
        position={position}
        lightSquareStyle={chessStyles.lightSquare}
        darkSquareStyle={chessStyles.darkSquare}
        boardStyle={{
          borderRadius: "5px",
          overflow: "hidden",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
        }}
      />
      <div className="flex flex-row justify-center gap-2 p-2 pt-4">
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={stepBackward}
          disabled={currentMoveIndex === 0}
        >
          Previous
        </button>
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={stepForward}
          disabled={currentMoveIndex >= moves.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};
